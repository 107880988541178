import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { join } from 'path';
import defaultTheme from 'tailwindcss/defaultTheme';
import plugin from 'tailwindcss/plugin';
export var colors = {
  motimate: {
    DEFAULT: '#26da9f'
  }
};
export default {
  content: [join(__dirname, '**/*!(*.stories|*.spec).{ts,tsx,html}')],
  theme: {
    extend: {
      colors: _objectSpread(_objectSpread({}, colors), {}, {
        'dark-blue': '#2883ff',
        emerald: {
          400: colors.motimate.DEFAULT
        },
        'block-bg': {
          DEFAULT: 'rgb(var(--color-block-bg-50) / <alpha-value>)',
          50: 'rgb(var(--color-block-bg-50) / <alpha-value>)',
          100: 'rgb(var(--color-block-bg-100) / <alpha-value>)',
          200: 'rgb(var(--color-block-bg-200) / <alpha-value>)',
          300: 'rgb(var(--color-block-bg-300) / <alpha-value>)',
          400: 'rgb(var(--color-block-bg-400) / <alpha-value>)',
          500: 'rgb(var(--color-block-bg-500) / <alpha-value>)',
          600: 'rgb(var(--color-block-bg-600) / <alpha-value>)',
          700: 'rgb(var(--color-block-bg-700) / <alpha-value>)',
          800: 'rgb(var(--color-block-bg-800) / <alpha-value>)',
          900: 'rgb(var(--color-block-bg-900) / <alpha-value>)'
        },
        'block-text': {
          DEFAULT: 'rgb(var(--color-block-text-800) / <alpha-value>)',
          50: 'rgb(var(--color-block-text-50) / <alpha-value>)',
          100: 'rgb(var(--color-block-text-100) / <alpha-value>)',
          200: 'rgb(var(--color-block-text-200) / <alpha-value>)',
          300: 'rgb(var(--color-block-text-300) / <alpha-value>)',
          400: 'rgb(var(--color-block-text-400) / <alpha-value>)',
          500: 'rgb(var(--color-block-text-500) / <alpha-value>)',
          600: 'rgb(var(--color-block-text-600) / <alpha-value>)',
          700: 'rgb(var(--color-block-text-700) / <alpha-value>)',
          800: 'rgb(var(--color-block-text-800) / <alpha-value>)',
          900: 'rgb(var(--color-block-text-900) / <alpha-value>)'
        },
        learning: {
          DEFAULT: 'rgb(var(--color-learning-500) / <alpha-value>)',
          50: 'rgb(var(--color-learning-50) / <alpha-value>)',
          100: 'rgb(var(--color-learning-100) / <alpha-value>)',
          200: 'rgb(var(--color-learning-200) / <alpha-value>)',
          300: 'rgb(var(--color-learning-300) / <alpha-value>)',
          400: 'rgb(var(--color-learning-400) / <alpha-value>)',
          500: 'rgb(var(--color-learning-500) / <alpha-value>)',
          600: 'rgb(var(--color-learning-600) / <alpha-value>)',
          700: 'rgb(var(--color-learning-700) / <alpha-value>)',
          800: 'rgb(var(--color-learning-800) / <alpha-value>)',
          900: 'rgb(var(--color-learning-900) / <alpha-value>)'
        },
        'learning-hex': {
          DEFAULT: 'var(--color-learning-hex-500)',
          50: 'var(--color-learning-hex-50)',
          100: 'var(--color-learning-hex-100)',
          200: 'var(--color-learning-hex-200)',
          300: 'var(--color-learning-hex-300)',
          400: 'var(--color-learning-hex-400)',
          500: 'var(--color-learning-hex-500)',
          600: 'var(--color-learning-hex-600)',
          700: 'var(--color-learning-hex-700)',
          800: 'var(--color-learning-hex-800)',
          900: 'var(--color-learning-hex-900)'
        },
        logo: 'var(--color-logo-background)',
        main: 'var(--color-main)',
        organization: {
          DEFAULT: 'rgb(var(--color-organization-main-500) / <alpha-value>)',
          background: 'rgb(var(--color-organization-background) / <alpha-value>)',
          'logo-background': 'rgb(var(--color-organization-logo-background) / <alpha-value>)',
          'main-feature': 'rgb(var(--color-organization-main-feature) / <alpha-value>)',
          secondary: 'rgb(var(--color-organization-secondary) / <alpha-value>)',
          'secondary-feature': 'rgb(var(--color-organization-secondary-feature) / <alpha-value>)',
          50: 'rgb(var(--color-organization-main-50) / <alpha-value>)',
          100: 'rgb(var(--color-organization-main-100) / <alpha-value>)',
          200: 'rgb(var(--color-organization-main-200) / <alpha-value>)',
          300: 'rgb(var(--color-organization-main-300) / <alpha-value>)',
          400: 'rgb(var(--color-organization-main-400) / <alpha-value>)',
          500: 'rgb(var(--color-organization-main-500) / <alpha-value>)',
          600: 'rgb(var(--color-organization-main-600) / <alpha-value>)',
          700: 'rgb(var(--color-organization-main-700) / <alpha-value>)',
          800: 'rgb(var(--color-organization-main-800) / <alpha-value>)',
          900: 'rgb(var(--color-organization-main-900) / <alpha-value>)'
        },
        'organization-hex': {
          DEFAULT: 'var(--color-organization-main-hex-500)',
          50: 'var(--color-organization-main-hex-50)',
          100: 'var(--color-organization-main-hex-100)',
          200: 'var(--color-organization-main-hex-200)',
          300: 'var(--color-organization-main-hex-300)',
          400: 'var(--color-organization-main-hex-400)',
          500: 'var(--color-organization-main-hex-500)',
          600: 'var(--color-organization-main-hex-600)',
          700: 'var(--color-organization-main-hex-700)',
          800: 'var(--color-organization-main-hex-800)',
          900: 'var(--color-organization-main-hex-900)'
        },
        primary: {
          200: '#94e5cb',
          500: '#25da9f',
          600: '#2dc89c'
        },
        red: {
          25: '#f9f7f6'
        },
        background: {
          DEFAULT: 'rgb(255 255 255 / <alpha-value>)',
          // white
          secondary: 'rgb(245 245 245 / <alpha-value>)',
          // neutral-100
          tertiary: 'rgb(229 229 229 / <alpha-value>)' // neutral-200
        },

        border: {
          DEFAULT: 'rgb(229 229 229 / <alpha-value>)',
          // neutral-200
          input: 'rgb(229 229 229 / <alpha-value>)' // neutral-200
        },

        icon: {
          DEFAULT: 'rgb(38 38 38 / <alpha-value>)',
          // neutral-800
          secondary: 'rgb(115 115 115 / <alpha-value>)' // neutral-500
        },

        status: {
          success: 'rgb(16 185 129 / <alpha-value>)',
          // emerald-500,
          danger: 'rgb(185 28 28 / <alpha-value>)',
          // red-500
          warning: 'rgb(180 83 9 / <alpha-value>)',
          // amber-500
          'success-dark': 'rgb(4 120 87 / <alpha-value>)',
          // emerald-700
          'danger-dark': 'rgb(185 28 28 / <alpha-value>)',
          // red-700
          'warning-dark': 'rgb(161 98 7 / <alpha-value>)',
          // amber-600
          'success-opacity': 'rgb(4 120 87 / 0.15)',
          // emerald-700
          'danger-opacity': 'rgb(185 28 28 / 0.15)',
          // red-700
          'warning-opacity': 'rgb(161 98 7 / 0.15)' // amber-600
        },

        typography: {
          DEFAULT: 'rgb(38 38 38 / <alpha-value>)',
          // neutral-800
          secondary: 'rgb(115 115 115 / <alpha-value>)',
          // neutral-500
          'contrast-ui': 'rgb(29 78 216 / <alpha-value>)' // blue-700
        }
      }),

      fontFamily: {
        sans: ['Inter'].concat(_toConsumableArray(defaultTheme.fontFamily.sans)),
        inter: ['Inter'].concat(_toConsumableArray(defaultTheme.fontFamily.sans)),
        'open-sans': ['"Open Sans"'].concat(_toConsumableArray(defaultTheme.fontFamily.sans)),
        ubuntu: ['Ubuntu'].concat(_toConsumableArray(defaultTheme.fontFamily.sans))
      },
      lineHeight: {
        ui: '1.1'
      },
      cursor: {
        grab: 'grab',
        grabbing: 'grabbing',
        initial: 'initial'
      },
      fontSize: {
        '4xs': '0.5625rem',
        '3xs': '0.625rem',
        '2xs': '0.6875rem',
        md: ['15px', '1'],
        'ui2-2xs': ['0.625rem', '0.9375rem'],
        'ui2-xs': ['0.75rem', '1.125rem'],
        'ui2-sm': ['0.875rem', '1.3125rem'],
        'ui2-base': ['1rem', '1.5rem'],
        'ui2-lg': ['1.125rem', '1.5rem'],
        'ui2-xl': ['1.25rem', '1.875rem'],
        'ui2-2xl': ['1.5rem', '2rem'],
        'ui2-3xl': ['1.875rem', '2.5rem'],
        'ui2-4xl': ['2rem', '3rem'],
        caption2: ['0.6875rem', '0.8125rem'],
        footnote: ['0.8125rem', '1.125rem'],
        callout: ['0.9375rem', '1.5rem'],
        subhead: ['0.9375rem', '1.5rem'],
        headline: ['1.0625rem', '1.375rem'],
        title2: ['1.375rem', '1.75rem'],
        title3: ['1.25rem', '1.5625rem'],
        'large-title': ['2.125rem', '2.625rem']
      },
      borderRadius: {
        inherit: 'inherit'
      },
      boxShadow: {
        100: '0 4px 4px -2px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 4px -1px rgba(0, 0, 0, 0.05)',
        200: '0px 2px 2px rgba(0, 0, 0, 0.05), 0px 8px 16px -8px rgba(0, 0, 0, 0.05), 0px 8px 16px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03)',
        300: '0px 8px 32px -8px rgba(0, 0, 0, 0.12), 0px 8px 24px rgba(0, 0, 0, 0.02), 0px 8px 16px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 4px 8px rgba(0, 0, 0, 0.02)',
        400: '0px 16px 32px -16px rgba(0, 0, 0, 0.05), 0px 16px 38px -4px rgba(0, 0, 0, 0.03), 0px 16px 32px -4px rgba(0, 0, 0, 0.03), 0px 8px 24px rgba(0, 0, 0, 0.03), 0px 8px 16px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03)',
        500: '0px 16px 48px -9px rgba(0, 0, 0, 0.02), 0px 16px 32px rgba(0, 0, 0, 0.02), 0px 4px 8px rgba(0, 0, 0, 0.02)',
        "default": '0 10px 20px 0 rgba(46, 51, 56, 0.1)',
        'ui2-xs': '0px 1px 2px 0px rgba(33, 41, 54, 0.03), 0px 1px 0px 0px rgba(33, 41, 54, 0.02)',
        'ui2-sm': '0px 3px 5px -1px rgba(33, 41, 54, 0.02), 0px 1px 2px 0px rgba(33, 41, 54, 0.04)',
        'ui2-lg': '0px 2px 6px 0px rgba(33, 41, 54, 0.06), 0px 32px 42px -23px rgba(33, 41, 54, 0.07);',
        'ui2-xl': '0px 24px 48px -8px rgba(33, 41, 54, 0.11), 0px 48px 64px -32px rgba(33, 41, 54, 0.13);'
      },
      maxWidth: {
        175: '43.75rem' // 700px
      },

      minWidth: {
        175: '43.75rem' // 700px
      },

      screens: {
        mobile: {
          max: '799px'
        },
        tablet: '800px',
        desktopsm: '1080px',
        desktop: '1200px'
      },
      spacing: {
        12.5: '3.125rem',
        // 50px
        15: '3.75rem',
        // 60px
        22: '5.5rem',
        // 88px
        30: '7.5rem',
        // 120px
        120: '30rem',
        // 480px
        175: '43.75rem',
        // 700px
        '9/16': '56.25%'
      },
      textShadow: {
        xs: '0  1px  2px rgba(33, 41, 54, 0.03), 0  1px  0   rgba(33, 41, 54, 0.02);',
        sm: '0  3px  5px rgba(33, 41, 54, 0.02), 0  1px  2px rgba(33, 41, 54, 0.04)',
        md: '0  1px  1px rgba(33, 41, 54, 0.04), 0  3px  4px rgba(33, 41, 54, 0.04)',
        lg: '0  2px  6px rgba(33, 41, 54, 0.06), 0 32px 42px rgba(33, 41, 54, 0.07)',
        xl: '0 24px 48px rgba(33, 41, 54, 0.11), 0 48px 64px rgba(33, 41, 54, 0.13)'
      },
      zIndex: {
        lightbox: '1075',
        draggedElement: '1076'
      },
      animation: {
        'scale-in': 'scaleIn 150ms ease-in',
        'scale-out': 'scaleOut 150ms ease-in',
        'fade-in': 'fadeIn 150ms ease-in',
        'fade-out': 'fadeOut 150ms ease-in',
        'fade-in-150': 'fadeIn30 150ms ease-in',
        'fade-out-150': 'fadeIn30 150ms ease-out',
        'fade-in-300': 'fadeIn30 300ms ease-in',
        'fade-out-300': 'fadeOut30 300ms ease-out',
        'fade-in-scale': 'fadeInScale 150ms ease',
        highlight: 'highlight 5s',
        'slide-in-bottom': 'slideInBottom 150ms ease-out',
        'slide-out-bottom': 'slideOutBottom 150ms ease-in',
        'fade-out-scale': 'fadeOutScale 150ms ease',
        'slide-in-left': 'slideInLeft 150ms ease-out',
        'slide-out-left': 'slideOutLeft 150ms ease-in',
        'slide-down-and-fade': 'slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        'slide-left-and-fade': 'slideLeftAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        'slide-up-and-fade': 'slideUpAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        'slide-right-and-fade': 'slideRightAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        wave: 'wave 8s ease-in-out infinite',
        'scale-and-fade-in': 'scaleAndFadeIn 150ms ease-in',
        'scale-and-fade-out': 'scaleAndFadeOut 150ms ease-in'
      },
      keyframes: {
        scaleIn: {
          '0%': {
            transform: 'scale(.96)'
          },
          '100%': {
            transform: 'scale(1)'
          }
        },
        scaleOut: {
          '0%': {
            transform: 'scale(1)'
          },
          '100%': {
            transform: 'scale(.96)'
          }
        },
        fadeIn: {
          '0%': {
            opacity: '0'
          },
          '100%': {
            opacity: '1'
          }
        },
        fadeOut: {
          '0%': {
            opacity: '1'
          },
          '100%': {
            opacity: '0'
          }
        },
        fadeIn30: {
          '0%': {
            opacity: '0'
          },
          '100%': {
            opacity: '0.3'
          }
        },
        fadeOut30: {
          '0%': {
            opacity: '0.3'
          },
          '100%': {
            opacity: '0'
          }
        },
        fadeInScale: {
          '0%': {
            opacity: '0',
            transform: 'translate(-50%, -48%) scale(.96)'
          },
          '100%': {
            opacity: '1',
            transform: 'translate(-50%, -50%) scale(1)'
          }
        },
        slideInBottom: {
          '0%': {
            transform: 'translateY(100%)'
          },
          '100%': {
            transform: 'translateY(0%)'
          }
        },
        slideOutBottom: {
          '0%': {
            transform: 'translateY(0%)'
          },
          '100%': {
            transform: 'translateY(100%)'
          }
        },
        fadeOutScale: {
          '0%': {
            opacity: '1',
            transform: 'translate(-50%, -50%) scale(1)'
          },
          '100%': {
            opacity: '0',
            transform: 'translate(-50%, -48%) scale(.96)'
          }
        },
        slideInLeft: {
          from: {
            transform: 'translate3d(100%,0,0)'
          },
          to: {
            transform: 'translate3d(0,0,0)'
          }
        },
        slideOutLeft: {
          '0%': {
            transform: 'translate3d(0,0,0)'
          },
          '100%': {
            transform: 'translate3d(100%,0,0)'
          }
        },
        wave: {
          from: {
            backgroundPosition: '200% 0'
          },
          to: {
            backgroundPosition: '-200% 0'
          }
        },
        slideDownAndFade: {
          from: {
            opacity: '0',
            transform: 'translateY(-2px)'
          },
          to: {
            opacity: '1',
            transform: 'translateY(0)'
          }
        },
        slideLeftAndFade: {
          from: {
            opacity: '0',
            transform: 'translateX(2px)'
          },
          to: {
            opacity: '1',
            transform: 'translateX(0)'
          }
        },
        slideUpAndFade: {
          from: {
            opacity: '0',
            transform: 'translateY(2px)'
          },
          to: {
            opacity: '1',
            transform: 'translateY(0)'
          }
        },
        slideRightAndFade: {
          from: {
            opacity: '0',
            transform: 'translateX(-2px)'
          },
          to: {
            opacity: '1',
            transform: 'translateX(0)'
          }
        },
        highlight: {
          from: {
            backgroundColor: '#fef3c7'
          },
          to: {
            backgroundColor: '#f5f5f5'
          }
        },
        scaleAndFadeIn: {
          from: {
            opacity: '0',
            transform: 'scale(0.7)'
          },
          to: {
            opacity: '1',
            transform: 'scale(1)'
          }
        },
        scaleAndFadeOut: {
          from: {
            opacity: '1',
            transform: 'scale(1)'
          },
          to: {
            opacity: '0',
            transform: 'scale(0.7)'
          }
        }
      }
    }
  },
  plugins: [plugin(function (_ref) {
    var matchUtilities = _ref.matchUtilities,
      theme = _ref.theme;
    matchUtilities({
      'text-shadow': function textShadow(value) {
        return {
          textShadow: value
        };
      }
    }, {
      values: theme('textShadow')
    });
  }), require('@tailwindcss/container-queries')]
};